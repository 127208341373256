import React from "react";
import Link from "next/link"

import Store from '/src/store'

import styles from './shopsCatsMenu.module.sass'

import UrlGenerator from '/src/common/helpers/urlGenerator'


export default class ShopsCatsMenu extends React.Component {


  updateStateFromStore = () => {
    const currentState = this.getCurrentStateFromStore();
    if (this.state !== currentState) {
      this.setState(currentState);
    }
  }


  getCurrentStateFromStore() {
    return {
      shopsCats: Store.getState().shopsCats['list'],
    }
  }
  
  componentDidMount() {
    // this.searchInputRef.focus();
    this.unsubscribeStore = Store.subscribe(this.updateStateFromStore);

    this.setState({
      shopsCats: Store.getState().shopsCats['list'],
    })
  }
  
  componentWillUnmount() {
    this.unsubscribeStore();
  }


  constructor(props) {

    // setHtml('')

    super(props);

    this.state = {
      shopsCats: [],

    };

    


  }

  render() {

    return <div className={styles.links}>

      {/*<Link href="/promos" className={`${styles.link} ${styles._promo}`}>
        Акции
      </Link>*/}

      {this.state.shopsCats.map(shopsCat => {
        return <Link href={UrlGenerator({cat: [shopsCat.alias]})} className={styles.link} key={shopsCat.id}>
          {shopsCat.title}
        </Link>
      })}


{/*
      <Link href="/addresses" className={styles.link}>
        Скидки
      </Link>

      <Link href="/addresses" className={styles.link}>
        Новинки
      </Link>

      <Link href="/addresses" className={styles.link}>
        До 1 000 рублей
      </Link>

      <Link href="/addresses" className={styles.link}>
        Салоны оптики
      </Link>

      <Link href="/services" className={styles.link}>
        Услуги
      </Link>
*/}
    </div>

  }

}
