import Link from "next/link"

import PriceFormater from '/src/common/helpers/priceFormater'
import ImageRenderer from '/src/common/components/imageRenderer'

import styles from './shopsListItem.module.sass'

import UrlGenerator from '/src/common/helpers/urlGenerator'

const ShopsListItem = (props) => {

  if (typeof props.shopsItem == "undefined") {
    return
  }

  const shopsItem = props.shopsItem;

  return(
    <div className={styles.item}>

      <div className={styles.item_container}>

        <div className={styles.icons}>
          {shopsItem.shops_items_labels && shopsItem.shops_items_labels.map(label => {
            return <span className={`${styles.icon} ${styles.icon_new}`} key={"shops_items_List_label_"+label.shops_items_label_id}>{label.title}</span>
          })}

          {shopsItem.discount > 0 && <span className={`${styles.icon} ${styles.icon_discount}`}>-{shopsItem.discount}%</span>}
        </div>

        
        
        <Link href={UrlGenerator({item: shopsItem})} className={styles.image} onClick={() => {
          window.dataLayer.push({
            "ecommerce": {
              "currencyCode": "RUB",
              "click" : {
                "products": [{
                  "id": shopsItem.shops_item_id,
                  "name" : shopsItem.title,
                  "price": shopsItem.price,
                  "quantity": 1
                }]
              }
            }
          });
        }}>

          {shopsItem.images.length > 0
            ? <ImageRenderer image={shopsItem.images[0]} alt={shopsItem.title} />
            : <img alt="" src="/template/no-photo.svg" /> 
          }


          {/*<img alt="Paul Vosheront 3005 1" src="https://www.eye-emporium.com/cdn/shop/products/clariti_1_day_toric.png?v=1611141707&width=400" />*/}

          

          {/*<img alt="Paul Vosheront 3005 1" src="https://www.eye-emporium.com/cdn/shop/files/0RB3016__W0365_000A.jpg?crop=center&v=1703168678&width=500" />*/}

        </Link>


        
        
        
          <Link href={UrlGenerator({item: shopsItem})} className={styles.title} onClick={() => {
            window.dataLayer.push({
              "ecommerce": {
                "currencyCode": "RUB",
                "click" : {
                  "products": [{
                    "id": shopsItem.shops_item_id,
                    "name" : shopsItem.title,
                    "price": shopsItem.price,
                    "quantity": 1
                  }]
                }
              }
            });
          }}>

            {/*<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Favorite">
              <path id="Icon" d="M7.93781 3.05714C8.28802 2.04964 9.71287 2.04964 10.0631 3.05714L10.9501 5.60899C11.1561 6.20159 11.7091 6.6034 12.3364 6.61618L15.0374 6.67123C16.1039 6.69296 16.5442 8.04807 15.6942 8.69247L13.5413 10.3246C13.0414 10.7037 12.8301 11.3538 13.0118 11.9543L13.7941 14.5402C14.103 15.5611 12.9503 16.3986 12.0748 15.7894L9.85722 14.2463C9.34224 13.8879 8.65865 13.8879 8.14368 14.2463L5.92612 15.7894C5.0506 16.3986 3.89787 15.5611 4.20675 14.5402L4.98907 11.9543C5.17074 11.3538 4.9595 10.7037 4.45956 10.3246L2.30671 8.69247C1.45674 8.04807 1.89704 6.69296 2.96345 6.67123L5.66451 6.61618C6.29176 6.6034 6.8448 6.20159 7.05079 5.60899L7.93781 3.05714Z" fill="#FFC700"></path>
              </g>
            </svg>*/}


            {shopsItem.title}
          </Link>
        

          <div className={styles.price}>
            {PriceFormater(shopsItem.price, shopsItem.price_old)}
          </div>
         
        

      </div>

    </div>

  )
}

export default ShopsListItem