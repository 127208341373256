import React from 'react';
import Link from "next/link"

import parse from "html-react-parser";

import styles from './contentCheck.module.sass'

const ContentCheck = (contents, params) => {

  return <div className={styles.block}>

      <div className={styles.content}>

        <div className={`h1 ${styles.title}`}>
          Бесплатная проверка зрения
        </div>

        <div className={styles.entry}>
          Забота о вашем зрении - это забота о вашем здоровье и комфорте. Если вы замечаете, что зрение стало менее четким, испытываете усталость глаз или возникли другие проблемы с зрением, то пришло время пройти проверку у специалистов.
        </div>
        <Link href="/check" className={`btn ${styles.btn}`}>
          Записаться на прием
        </Link>

      </div>


    </div>

}

export default ContentCheck