import React from 'react';

import Head from 'next/head';

const Meta = (seos) => {

  if (typeof (seos) == 'undefined') {
    return
  }

  return Object.keys(seos).length > 0 && <Head>
      <title>{seos.seo_title}</title>
      <meta name="description" content={seos.seo_desc} />
      <meta name="keywords" content={seos.seo_keyw} />
      <meta property="og:title" content={seos.seo_title} />
    </Head>

}

export default Meta